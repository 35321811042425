import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

export const optionsLine = {
  type: "line",
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart",
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};

export const optionsBar = {
  type: "bar",
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart",
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};

export default function NX_Chart(props) {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const chartData = () => {
    const depositCounts = Array(12).fill(0);
    const expenseCounts = Array(12).fill(0);

    props.data.forEach((activity) => {
      const date = new Date(activity.date);
      const month = date.getUTCMonth();
      if (activity.type === "deposit") {
        depositCounts[month]++;
      } else if (activity.type === "expense") {
        expenseCounts[month]++;
      }
    });

    return { deposits: depositCounts, expenses: expenseCounts };
  };

  const chartDataTotalBudget = () => {
    const lastActivity = {};
    props.data.forEach((activity) => {
      const date = new Date(activity.date);
      const month = date.getUTCMonth();
      if (!lastActivity[month] || date > new Date(lastActivity[month].date)) {
        lastActivity[month] = activity;
      }
    });

    const totalBudget = Array(12).fill(0);
    Object.values(lastActivity).forEach((activity) => {
      const date = new Date(activity.date);
      const month = date.getUTCMonth();
      totalBudget[month] = activity.current_budget;
    });
    return totalBudget;
  };

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Deposits",
        data: chartData().deposits,
        backgroundColor: "green",
      },
      {
        label: "Expenses",
        data: chartData().expenses,
        backgroundColor: "red",
      },
    ],
  };

  const dataLine = {
    labels,
    datasets: [
      {
        label: "Total budget",
        data: chartDataTotalBudget(),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  if (props.chart_type === "Total budget") {
    return <Line options={optionsLine} data={dataLine} />;
  } else {
    return <Bar options={optionsBar} data={dataBar} />;
  }
}
