import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import "./controls.scss";

function NxIconButton(props) {
  const style = props.style ? props.style : {};
  const { tooltip } = props;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onClick) {
      props.onClick();
    }
  };

  const renderElement = () => {
    if (tooltip && tooltip.length) {
      return (
        <Tooltip title={tooltip}>
          <IconButton
            aria-label="edit"
            size={props.size ? props.size : "small"}
            style={style}
            color="default"
            onClick={onClick}
          >
            {props.children}
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <IconButton
        aria-label="edit"
        size={props.size ? props.size : "small"}
        style={style}
        color="default"
        onClick={onClick}
      >
        {props.children}
      </IconButton>
    );
  };

  return renderElement();
}

export default NxIconButton;
