import React from "react";
import Nx_Ctrl_DateTime from "../../../nix_components/ui_components/controls/nx_ctrl_datetime";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button";

export default class ProfileFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: null,
      date_to: null,
    };
  }

  getDistinctCategories = async () => {
    try {
      const body = { user_id: localStorage.getItem("user_id") };
      global.UF.setMaskVisibility(true);
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/profile-categories`,
        body
      );
      const distinct_categories = JSON.parse(response?.text);
      this.setState({ distinct_categories }, () => {
        global.UF.setMaskVisibility(false);
      });
    } catch (err) {
      global.UF.handleError(err);
      return;
    }
  };

  handleApplyFilters = () => {
    this.props.handleApplyFilters(this.state);
  };

  render() {
    return (
      <>
        <Grid
          className={this.props.className}
          style={{ gridAutoFlow: "column", width: "50%", gap: "20px" }}
        >
          <Nx_Ctrl_DateTime
            type="number"
            label="From"
            value={this.state.date_from}
            onChange={(date_from) => this.setState({ date_from })}
            fieldConfig={{ min: 0, max: 100, label: "From" }}
          />
          <Nx_Ctrl_DateTime
            type="number"
            label="To"
            value={this.state.date_to}
            fieldConfig={{ min: 0, max: 100, label: "To" }}
            onChange={(date_to) => this.setState({ date_to })}
          />
          <Nx_Button
            label={"Apply Date"}
            variant="text"
            onClick={this.handleApplyFilters}
          />
        </Grid>
      </>
    );
  }
}
