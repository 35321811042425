import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./nx_breadcrumbs.scss";

export const NxBreadcrumb = (props) => {
  const { breadcrumb, pathname, index } = { ...props };
  const navigate = useNavigate();

  const handleBreadcrumbClick = () => {
    const split = pathname.split("/").filter((breadcrumb) => breadcrumb !== "");
    split.length = index + 1;
    const url = split.join("/");
    navigate(url);
  };

  const handleBreadcrumbLabel = (breadcrumb) => {
    if (breadcrumb.includes("-")) {
      breadcrumb = breadcrumb.split("-");
      breadcrumb = breadcrumb.map((b) => global.UF.capitalizeFirstLetter(b));
      breadcrumb = breadcrumb.join(" ");
      return breadcrumb;
    }
    return global.UF.capitalizeFirstLetter(breadcrumb);
  };

  return (
    <Chip
      style={{ cursor: "pointer", userSelect: "none" }}
      onClick={handleBreadcrumbClick}
      label={handleBreadcrumbLabel(breadcrumb)}
    />
  );
};

export const NxBreadcrumbs = (props) => {
  const pathname = props?.router?.location?.pathname;

  const breadcrumbs = pathname.split("/").filter((breadcrumb) => {
    return breadcrumb !== "";
  });

  const renderBreadcrumbs = () => {
    return breadcrumbs.map((breadcrumb, index) => {
      return (
        <div key={index}>
          <NxBreadcrumb
            breadcrumbs={breadcrumbs}
            pathname={pathname}
            index={index}
            breadcrumb={breadcrumb}
          />
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        marginLeft: "80px",
        alignItems: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "5px",
      }}
      className="nx_breadcrumbs"
    >
      <Breadcrumbs separator=">">{renderBreadcrumbs()}</Breadcrumbs>
    </div>
  );
};
