import React, { Fragment, useEffect, useState } from "react";
import Nx_Dialog from "../../../../nix_components/ui_components/dialogs/nx_dialog";
import {
  UI_Form_Row,
  UF_Form_Cell,
  UI_Form,
} from "../../../../nix_components/components/form_wrappers/controls/nx_form_controls";
import Nx_Ctrl_Generic from "../../../../nix_components/ui_components/controls/nx_ctrl_generic";
import Nx_Ctrl_TextInput from "../../../../nix_components/ui_components/controls/nx_ctrl_textinput";

export default function ParticipationExapandableModal({
  show,
  handleClose,
  type_id,
  type,
}) {
  const [data, setData] = useState(null);
  const [participations, setPartipations] = useState([]);
  const formConfigDeposit = {
    rows: [["deposit"], ["user_id"], ["date"]],
  };

  const formConfigExpanse = {
    rows: [
      ["category_id", "source_id"],
      ["user_id", "cost"],
      ["is_equal", "is_private"],
      ["date"],
    ],
  };

  const dataConfigs =
    global.UF.data_structure[type === "Expense" ? "expanses" : "deposits"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (type === "Expense") {
          const expenseDataItem = await global.UF.dataProvider.get_v2(
            "expanses",
            {
              id: type_id,
            }
          );

          const participations = await global.UF.dataProvider.get_v2(
            "view_participations",
            {
              expanse_id: type_id,
            }
          );
          setPartipations(participations);
          const expenseData = expenseDataItem[0];
          setData(expenseData);
        } else if (type === "Deposit") {
          const depositDataItem = await global.UF.dataProvider.get_v2(
            "deposits",
            {
              id: type_id,
            }
          );

          const depositData = depositDataItem[0];
          setData(depositData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (show) {
      fetchData();
    }
  }, [show, type, type_id]);

  const renderField = (field, col, index) => {
    let tempField = field.hasOwnProperty("length")
      ? dataConfigs.fields[field]
      : { ...dataConfigs.fields[field.field], ...field };
    return (
      <Fragment key={index}>
        <UF_Form_Cell cols={col}>
          <Nx_Ctrl_Generic
            read_only={true}
            dataItem={data}
            fieldConfig={tempField}
          />
        </UF_Form_Cell>
      </Fragment>
    );
  };

  const renderRow = (row, index) => {
    if (row && row.length) {
      return (
        <Fragment key={index}>
          <UI_Form_Row>
            {row.map((field, index) => {
              return renderField(field, row.length, index);
            })}
          </UI_Form_Row>
        </Fragment>
      );
    }
    return null;
  };

  const renderParticipations = () => {
    return (
      <Fragment>
        {participations.map((participant) => {
          return (
            <Nx_Ctrl_TextInput
              helperText={"Cost"}
              disabled={true}
              label={participant.user}
              value={participant.cost || ""}
            />
          );
        })}
      </Fragment>
    );
  };

  const renderContent = () => {
    if (data) {
      if (type === "Deposit") {
        return formConfigDeposit.rows.map((row, index) => {
          return renderRow(row, index);
        });
      } else if (type === "Expense") {
        let temp = formConfigExpanse.rows.map((row, index) => {
          return renderRow(row, index);
        });
        temp.push(renderParticipations());
        return temp;
      }
    }
  };

  return (
    <Nx_Dialog title={type} open={show} handleClose={handleClose}>
      <UI_Form>{renderContent()}</UI_Form>
    </Nx_Dialog>
  );
}
