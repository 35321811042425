import React, { useState } from "react";
import Grid from "../ui_components/layouts/Grid/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import "./nx_grid.scss";

const NxLoaderRequests = () => {
  const [show, setShow] = useState(false);

  const setLoader = (show) => {
    setShow(show);
  };

  global.loadingSetVisibility = setLoader;

  if (!show) return null;

  return (
    <Grid
      fullScreen
      style={{ visibility: show ? "unset" : "hidden" }}
      className={"loader"}
    >
      <CircularProgress color={"primary"} />
    </Grid>
  );
};

export default NxLoaderRequests;
