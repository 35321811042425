import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import ActivitiesFilter from "./ActivitiesFilter";
import ActivitiesClickModal from "./modals/ActivitiesClickModal";

export default class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: null };
  }

  gridProps = {
    fields: ["date", "user_id", "cost", "type"],
  };

  gridMobileProps = {
    fields: ["date", "user_id", "cost"],
  };

  generateAndSetFilters = (state) => {
    const filters = {};
    const { date_from, date_to } = state;

    const date_from_obj = new Date(date_from);
    const date_to_obj = new Date(date_to);
    const mysqlTimeStampFrom = date_from_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const mysqlTimeStampTo = date_to_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    if (date_from || date_to) {
      filters.custom_filter = `${
        mysqlTimeStampTo
          ? `'${mysqlTimeStampTo}' >= view_recent_activities.date`
          : ""
      } ${mysqlTimeStampTo && mysqlTimeStampFrom ? "AND" : ""} ${
        mysqlTimeStampFrom
          ? `view_recent_activities.date >= '${mysqlTimeStampFrom}'`
          : ""
      }`;
    }

    this.setState({ filters }, () => {
      global.UF.dataProvider.datastructure["view_recent_activities"].filters =
        this.state.filters;
      this.fetchData();
    });
  };

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  renderCustomInfo = () => {
    return (
      <div>
        <div>Name: {this.state.profile?.name}</div>
        <div>Budget: {this.state.profile?.budget}</div>
        <div>Participations: {this.state.profile?.participations}</div>
      </div>
    );
  };

  handleRowClick = async (row) => {
    this.setState({ activeItem: row.row });
  };

  renderExpandableModal = () => {
    const { type_id, type } = this.state.activeItem;
    return (
      <ActivitiesClickModal
        show={Boolean(this.state.activeItem)}
        handleClose={() => this.setState({ activeItem: null })}
        type_id={type_id}
        type={type}
      />
    );
  };

  render() {
    return (
      <Fragment>
        {Boolean(this.state.activeItem) && this.renderExpandableModal()}
        <UF_Content
          table="view_recent_activities"
          title="Recent Activities"
          permission="user"
          gridProps={this.gridProps}
          gridMobileProps={this.gridMobileProps}
          fetchData={this.getFetchData}
          onRowClick={this.handleRowClick}
          edit={false}
          export={false}
          delete={false}
          add={false}
          sync={false}
        />
      </Fragment>
    );
  }
}
