import React from "react";

import withRouter from "../../../nix_components/components/withRouter";

class ProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { profile } = this.props;
    return (
      <div className="nx_profile_info">
        <div>Logged in as: {profile?.name}</div>
        <div>
          Budget:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.budget)}
        </div>
        <div>
          Total expense this week:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.total_expense_this_week)}
        </div>
        <div>
          Total deposit this week:{" "}
          {new Intl.NumberFormat("sr-SR", {
            style: "currency",
            currency: "RSD",
          }).format(profile?.total_deposit_this_week)}
        </div>
      </div>
    );
  }
}

export default withRouter(ProfileInfo);
