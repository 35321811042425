import React, { Fragment } from "react";
import Paper from "@mui/material/Paper/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Icon from "@mui/material/Icon/Icon";
import withRouter from "../../withRouter";
import "../nx_filters.scss";

class Nx_Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };

    this.data_structure = global.UF.data_structure[this.props.table];
    this.searchFields = this.data_structure.searchFields
      ? this.data_structure.searchFields
      : this.data_structure.fieldsArr.map((field) => field.field);
    this.listFields = this.data_structure.listFields
      ? this.data_structure.listFields
      : [];

    this.reference_fields = [];
    this.search_fields = [];

    this.searchkw = {};

    this.data_structure.fieldsArr.forEach((field) => {
      if (this.searchFields.includes(field.field)) {
        if (field.hasOwnProperty("reference")) {
          if (
            global.UF.dataProvider.referenceProvider.references.hasOwnProperty(
              field.reference
            )
          ) {
            this.searchkw[field.field] = "%rf%";
            this.reference_fields.push(field);
          }
        } else {
          this.search_fields.push(field.field);
          this.searchkw[field.field] = "%kw%";
        }
      }
    });

    this.searchkw = JSON.stringify(this.searchkw);
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  };

  timerRef = null;

  replaceAllKeyWords = (kw) => {
    kw = kw.replaceAll("'", "''");
    this.searchResult = this.searchkw.replaceAll("%kw%", "%" + kw + "%");
    if (this.reference_fields && this.reference_fields.length) {
      let tempSearchKw = JSON.parse(this.searchResult);
      this.reference_fields.forEach((field) => {
        const data = global.UF.dataProvider.referenceProvider.search(
          field.reference,
          kw
        );
        if (data.length) {
          if (this.listFields.includes(field.field)) {
            let tempArr = [];
            data.forEach((record) => {
              record = record.substring(1, record.length - 2);
              tempArr.push(`'%${record}%'`);
            });
            tempSearchKw[field.field] = tempArr;
          } else {
            tempSearchKw[field.field] = `(${[...data]})`;
          }
        } else {
          delete tempSearchKw[`${field.field}`];
        }
      });
      this.searchResult = JSON.stringify(tempSearchKw);
      return this.searchResult;
    } else {
      return this.searchResult;
    }
  };

  onSearchChange = (event) => {
    let search = event.target.value;
    this.onSearch(search);
  };

  componentDidMount() {
    if (this.props.initialSearch) {
      this.props.initialSearch(this.props.router.location.search, (search) =>
        this.onSearch(search)
      );
    }
  }

  onSearch = (search) => {
    this.setState({ search });
    if (this.props.onFilterChange) {
      if (this.timerRef) {
        clearTimeout(this.timerRef);
      }
      this.timerRef = setTimeout(() => {
        if (this.unmounted) {
          return;
        }
        search = search.replace(/[\(\)]/g, "");
        const kw = search.toLowerCase().split(" ");
        if (kw.length > 0) {
          let tempSearchArr = [];
          kw.forEach((word) => {
            word = word.trim();
            if (word.length) {
              tempSearchArr.push(this.replaceAllKeyWords(word));
            }
          });
          this.props.onFilterChange("s", tempSearchArr);
        } else {
          this.props.onFilterChange("s", null);
        }
      }, 1000);
    }
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  render() {
    return (
      <Fragment>
        <Paper className="nx_search" component="form">
          <Icon
            type="button"
            sx={{ p: "10px", boxShadow: "unset" }}
            aria-label="search"
          >
            <SearchIcon />
          </Icon>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={this.state.search}
            onChange={this.onSearchChange}
            placeholder="Search"
            autoFocus={this.props.autoFocus}
            onKeyDown={this.onKeyDown}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default withRouter(Nx_Search);
