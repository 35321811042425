const referenceTypes = {
  local: "local",
  remote: "remote",
};

const supplierStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const supplierLocationStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const supplierContactStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const fileStatuses = {
  running: "running",
  error: "error",
  completed: "completed",
};

const inventoryDeactivationStatus = {
  yes: "yes",
  no: "no",
};

const inventoryAllowQuantityRestrictionStatus = {
  yes: "yes",
  no: "no",
};

const role = {
  Admin: "Admin",
  Customer: "Customer",
};

const customerStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const customersMarginTypes = {
  text: "Text",
  trade: "Trade",
};

const recentActivitiesTypes = {
  deposit: "Deposit",
  expense: "Expense",
};

const customerContactsStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const customerLocationsStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const currenciesStatusActive = {
  yes: "yes",
  no: "no",
};

const bookTypes = {
  text: "Text",
  trade: "Trade",
  both: "Both",
};

const customerBookTypes = {
  text: "Text",
  trade: "Trade",
  both: "Both",
};

const calculationTypes = {
  per_book: "Per Book",
  per_kg: "Per kg",
};

const quantityRestictionIntervals = {
  1: "Day",
  7: "Week",
  30: "Month",
  365: "Year",
};

const stockLeadIntervals = {
  1: "Day",
  7: "Week",
  30: "Month",
  365: "Year",
};

const leadIntervals = {
  1: "Day",
  7: "Week",
  30: "Month",
  365: "Year",
};

const offerPreparationCondition = {
  new: "new",
  used: "used",
};

const offerPreparationType = {
  text: "text",
  trade: "trade",
};

const usersStatusActive = {
  1: "yes",
  0: "no",
};

const offerPreparationError = {
  null: "None",
  1: "Missing cost price",
  2: "Missing quantity",
  3: "Missing sell price",
  4: "Price is too high",
  5: "Price is too low",
};

const referenceConfigs = {
  users: {
    id: "id",
    label: (dataItem) => dataItem.first_name + " " + dataItem.last_name,
    type: referenceTypes.remote,
    table: "users",
  },
  expanses_categories: {
    id: "id",
    label: "category",
    type: referenceTypes.remote,
    table: "expanses_categories",
  },
  expanses_sources: {
    id: "id",
    label: "source",
    type: referenceTypes.remote,
    table: "expanses_sources",
  },
  countries: {
    id: "id",
    label: "country",
    type: referenceTypes.remote,
    table: "countries",
  },
  offer_preperation_errors: {
    id: "id",
    label: "error",
    type: referenceTypes.remote,
    table: "offer_preperation_errors",
  },
  publishers: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "publishers",
    filters: {
      status: "1",
    },
  },
  suppliers: {
    id: "id",
    label: "supplier_name",
    type: referenceTypes.remote,
    table: "suppliers",
  },
  suppliers_stock: {
    id: "id",
    label: "supplier_name",
    type: referenceTypes.remote,
    table: "suppliers",
  },
  customers: {
    id: "id",
    label: "customer_name",
    type: referenceTypes.remote,
    table: "customers",
    filters: {},
  },
  currencies: {
    id: "id",
    label: "currency_code",
    type: referenceTypes.remote,
    table: "currencies",
    filters: {},
  },
  currencies_active: {
    id: "id",
    label: "currency_code",
    type: referenceTypes.remote,
    table: "currencies",
    filters: {
      is_active: 1,
    },
  },
  app_files: {
    id: "id",
    label: "id",
    type: referenceTypes.remote,
    table: "app_files",
  },
  view_offer_customer_created_at: {
    id: "id",
    label: "full_name",
    type: referenceTypes.remote,
    table: "view_offer_customer_created_at",
  },
  usersStatusActive: {
    type: referenceTypes.local,
    source: usersStatusActive,
  },
  offerPreparationErrors: {
    type: referenceTypes.local,
    source: offerPreparationError,
  },
  supplierStatuses: {
    type: referenceTypes.local,
    source: supplierStatuses,
  },
  offerPreparationCondition: {
    type: referenceTypes.local,
    source: offerPreparationCondition,
  },
  offerPreparationType: {
    type: referenceTypes.local,
    source: offerPreparationType,
  },
  currenciesStatusActive: {
    type: referenceTypes.local,
    source: currenciesStatusActive,
  },
  bookTypes: {
    type: referenceTypes.local,
    source: bookTypes,
  },
  calculationTypes: {
    type: referenceTypes.local,
    source: calculationTypes,
  },
  fileStatuses: {
    type: referenceTypes.local,
    source: fileStatuses,
  },
  inventoryDeactivationStatus: {
    type: referenceTypes.local,
    source: inventoryDeactivationStatus,
  },
  inventoryAllowQuantityRestrictionStatus: {
    type: referenceTypes.local,
    source: inventoryAllowQuantityRestrictionStatus,
  },
  leadIntervals: {
    type: referenceTypes.local,
    source: leadIntervals,
  },
  quantityRestictionIntervals: {
    type: referenceTypes.local,
    source: quantityRestictionIntervals,
  },
  stockLeadIntervals: {
    type: referenceTypes.local,
    source: stockLeadIntervals,
  },
  supplierLocationStatuses: {
    type: referenceTypes.local,
    source: supplierLocationStatuses,
  },
  supplierContactStatuses: {
    type: referenceTypes.local,
    source: supplierContactStatuses,
  },
  customerStatuses: {
    type: referenceTypes.local,
    source: customerStatuses,
  },
  role: {
    type: referenceTypes.local,
    source: role,
  },
  customerBookTypes: {
    type: referenceTypes.local,
    source: customerBookTypes,
  },
  customerContactsStatuses: {
    type: referenceTypes.local,
    source: customerContactsStatuses,
  },
  customerLocationsStatuses: {
    type: referenceTypes.local,
    source: customerLocationsStatuses,
  },
  customersMarginTypes: {
    type: referenceTypes.local,
    source: customersMarginTypes,
  },

  recentActivitiesTypes: {
    type: referenceTypes.local,
    source: recentActivitiesTypes,
  },
};

class DataReferences {
  references = {};
  reference_configs = referenceConfigs;
  data_provider = null;

  constructor(data_provider) {
    this.data_provider = data_provider;

    Object.keys(referenceConfigs).forEach((key) => {
      if (referenceConfigs[key].type == referenceTypes.local) {
        this.references[key] = [];
        Object.keys(referenceConfigs[key].source).forEach((item) => {
          this.references[key].push({
            id: item,
            label: referenceConfigs[key].source[item],
          });
        });
      }
    });
  }

  search = (reference, search) => {
    const data = this.references[reference];
    if (data) {
      let tempData = data.map((ref) => {
        if (ref.label.toLowerCase().includes(search.toLowerCase())) {
          return `'${ref.id.toString()}'`;
        } else {
          return null;
        }
      });
      tempData = tempData.filter((ref) => ref !== null);
      return tempData;
    }
  };

  getReferenceLabel_v2 = async (reference, value) => {
    let label = "";
    if (reference && value) {
      if (!this.references[reference]) {
        const data = await this.get_v2(reference);
        label = data.find((ref) => ref.id == value)?.label || "";
      } else {
        label =
          this.references[reference].find((ref) => ref.id == value)?.label ||
          "";
      }
      return label;
    }
  };

  getReferenceLabel = (reference, value) => {
    let label = "";
    if (reference !== null && this.references[reference] && value !== null) {
      label = this.references[reference].find((ref) => ref.id == value)
        ? this.references[reference].find((ref) => ref.id == value).label
        : "";
    }
    return label;
  };

  get = (reference, callBack, force = false) => {
    if (
      this.references[reference] &&
      (force == false ||
        referenceConfigs[reference].type == referenceTypes.local)
    ) {
      setTimeout(() => {
        callBack(this.references[reference]);
      }, 100);
    } else {
      let filters = referenceConfigs[reference].filters
        ? referenceConfigs[reference].filters
        : {};
      const table = referenceConfigs[reference].table;
      this.data_provider.get(table, filters, (data) => {
        this.references[reference] = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });
        callBack(this.references[reference]);
      });
    }
  };

  get_v2 = async (reference, force = false) => {
    if (
      this.references[reference] &&
      (force == false ||
        referenceConfigs[reference].type == referenceTypes.local)
    ) {
      return Promise.resolve(this.references[reference]);
    } else {
      let filters = referenceConfigs[reference].filters
        ? referenceConfigs[reference].filters
        : {};
      const table = referenceConfigs[reference].table;

      try {
        const data = await this.data_provider.get_v2(table, filters);

        this.references[reference] = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });

        return this.references[reference];
      } catch (error) {
        console.error("Error:", error);
        return [];
      }
    }
  };
}

export default DataReferences;
