import React, { Component } from "react";
import Nx_Dialog from "../../../../nix_components/ui_components/dialogs/nx_dialog";
import Grid from "../../../../nix_components/ui_components/layouts/Grid/Grid";
import Nx_Ctrl_Autocomplete from "../../../../nix_components/ui_components/controls/nx_ctrl_autocomplete";
import Nx_Ctrl_Select from "../../../../nix_components/ui_components/controls/nx_ctrl_select";
import Nx_Ctrl_TextInput from "../../../../nix_components/ui_components/controls/nx_ctrl_textinput";
import Nx_Ctrl_Checkbox from "../../../../nix_components/ui_components/controls/nx_ctrl_checkbox";
import Nx_Ctrl_DateTime from "../../../../nix_components/ui_components/controls/nx_ctrl_datetime";
import Nx_Loader from "../../../../nix_components/components/nx_loader";
import data_types from "../../../../nix_components/data/data_types";

export default class ExpansesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataItem: props.dataItem,
      isNew: props.dataItem.isNew,
      userId: props.user_id,
      participants_data: [],
      loading: true,
    };
    this.fetchUsers();
  }

  buttons = [
    {
      label: "Cancel",
      variant: "text",
      onClick: this.props.handleClose,
    },
    {
      label: "Submit",
      variant: "text",
      onClick: () => this.handleAddingExpanse(),
    },
  ];

  fetchUsers = async () => {
    const response = await global.UF.dataProvider.get_v2(`users`, {
      is_active: 1,
    });
    const mapped_response = response.map((item) => {
      return {
        id: item.id,
        label: item.first_name + " " + item.last_name,
        cost: null,
      };
    });
    this.setState({ participants_data: mapped_response, loading: false });
  };

  handleCategoryChange = (value) => {
    const { dataItem } = this.state;
    dataItem.category_id = value.id;
    this.setState({ dataItem });
  };

  handleSourceChange = (value) => {
    const { dataItem } = this.state;
    dataItem.source_id = value.id;
    this.setState({ dataItem });
  };

  handleUserChange = (value) => {
    const { dataItem } = this.state;
    dataItem.user_id = value;
    this.setState({ dataItem });
  };

  handleCostChange = (value) => {
    const { dataItem } = this.state;
    dataItem.cost = value;
    const { participants } = dataItem;
    if (participants && participants.length) {
      participants.forEach((participant) => {
        participant.cost = value / participants.length;
      });
    }
    this.setState({ dataItem });
  };

  handleIsEqualChange = (value) => {
    const { dataItem } = this.state;
    dataItem.is_equal = value;
    if (value && dataItem.cost) {
      dataItem.participants.forEach((participant) => {
        participant.cost = Number(
          dataItem.cost / dataItem.participants.length
        ).toFixed(2);
      });
    }

    this.setState({ dataItem });
  };

  handleIsPrivateChange = (value) => {
    const { dataItem } = this.state;
    dataItem.is_private = value;
    this.setState({ dataItem });
  };

  handleDateChange = (value) => {
    const { dataItem } = this.state;
    dataItem.date = value;
    this.setState({ dataItem });
  };

  handleNotesChange = (value) => {
    const { dataItem } = this.state;
    dataItem.notes = value;
    this.setState({ dataItem });
  };

  handleParticipantsChange = (value) => {
    const { dataItem, participants_data } = this.state;
    const temp_participant = participants_data.filter((item) =>
      value.includes(item.id)
    );
    temp_participant.forEach((item) => {
      item.cost = dataItem.cost
        ? Number(dataItem.cost / temp_participant.length).toFixed(2)
        : 0;
    });
    dataItem.participants = temp_participant;
    this.setState({ dataItem });
  };

  renderForm = () => {
    const { dataItem } = this.state;
    return (
      <Grid
        className={"uf_form"}
        style={{
          marginTop: "25px",
        }}
      >
        <Grid style={{ gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
          <Nx_Ctrl_Autocomplete
            value={dataItem["category_id"]}
            dataItem={dataItem}
            fieldConfig={{
              field: "category_id",
              name: "category_id",
              label: "Category",
              reference: "expanses_categories",
              addable: true,
              addable_field: "category",
            }}
            onChange={this.handleCategoryChange}
          />
          <Nx_Ctrl_Autocomplete
            value={dataItem["source_id"]}
            dataItem={dataItem}
            fieldConfig={{
              field: "source_id",
              name: "source_id",
              label: "Source",
              reference: "expanses_sources",
              addable: true,
              addable_field: "source",
            }}
            onChange={this.handleSourceChange}
          />
        </Grid>
        <Grid style={{ gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
          <Nx_Ctrl_Select
            value={
              dataItem["user_id"]
                ? dataItem["user_id"]
                : localStorage.getItem("user_id")
            }
            fieldConfig={{
              field: "user_id",
              label: "User",
              reference: "users",
            }}
            onChange={this.handleUserChange}
          />
          <Nx_Ctrl_TextInput
            value={dataItem["cost"]}
            type="number"
            fieldConfig={{
              field: "cost",
              label: "Cost",
              min: 0,
            }}
            onChange={this.handleCostChange}
          />
        </Grid>
        <Grid style={{ gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
          <Nx_Ctrl_Checkbox
            value={dataItem["is_equal"]}
            fieldConfig={{
              field: "is_equal",
              label: "Equal",
            }}
            onChange={this.handleIsEqualChange}
          />
          <Nx_Ctrl_Checkbox
            value={dataItem["is_private"]}
            fieldConfig={{
              field: "is_private",
              label: "Private",
            }}
            onChange={this.handleIsPrivateChange}
          />
        </Grid>
        <Grid style={{ width: "50%" }}>
          <Nx_Ctrl_DateTime
            value={dataItem["date"]}
            onChange={this.handleDateChange}
            fieldConfig={{
              field: "date",
              label: "Date",
              datatype: data_types.date,
            }}
          />
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Nx_Ctrl_TextInput
            value={dataItem["notes"]}
            multiline={true}
            fieldConfig={{
              field: "notes",
              label: "Notes",
            }}
            onChange={this.handleNotesChange}
          />
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Nx_Ctrl_Select
            value={
              dataItem["participants"] && dataItem["participants"].length
                ? dataItem["participants"].map((item) =>
                    item.hasOwnProperty("id") ? item.id : item
                  )
                : []
            }
            data={this.state.participants_data}
            onChange={this.handleParticipantsChange}
            fieldConfig={{
              field: "participants",
              label: "Participants",
              multiple: true,
            }}
          />
        </Grid>
        <Grid>{this.renderParticipantsSection()}</Grid>
      </Grid>
    );
  };

  renderParticipantsSection = () => {
    const { dataItem } = this.state;
    const participants = dataItem.participants;

    if (!Array.isArray(participants)) {
      return null;
    }

    if (!participants.length) {
      return null;
    }

    return (
      <Grid
        style={{
          gridTemplateRows: `repeat(${participants.length}, 1fr)`,
          gap: "25px",
        }}
      >
        {participants.map((participant, index) => {
          return (
            <Nx_Ctrl_TextInput
              key={index}
              read_only={dataItem.is_equal}
              value={participant["cost"]}
              type="number"
              fieldConfig={{
                field: "cost",
                label: "Cost",
                min: 0,
              }}
              onChange={(value) =>
                this.handleParticipantCostChange(participant, value)
              }
            />
          );
        })}
      </Grid>
    );
  };

  handleParticipantCostChange = (participant, value) => {
    const { dataItem } = this.state;
    const { participants } = dataItem;
    participant.cost = value;
    dataItem.cost = participants.reduce((acc, participant) => {
      const cost = isNaN(parseFloat(participant.cost))
        ? 0
        : parseFloat(participant.cost);
      return acc + cost;
    }, 0);
    this.setState({ dataItem });
  };

  handleValidation = () => {
    const { dataItem } = this.state;

    if (!dataItem.category_id) {
      global.UF.setAlertVisibility(true, "Category is required", "error");
      return false;
    }

    if (!dataItem.source_id) {
      global.UF.setAlertVisibility(true, "Source is required", "error");
      return false;
    }

    if (!dataItem.user_id) {
      global.UF.setAlertVisibility(true, "User is required", "error");
      return false;
    }

    if (!dataItem.cost) {
      global.UF.setAlertVisibility(true, "Cost is required", "error");
      return false;
    }

    if (!dataItem.participants || !dataItem.participants.length) {
      global.UF.setAlertVisibility(
        true,
        "Participants are required, you need to select at least one participant",
        "error"
      );
      return false;
    }

    return true;
  };

  handleAddingExpanse = async () => {
    if (!this.handleValidation()) {
      return;
    }

    const { dataItem } = this.state;
    const body = {
      category_id: dataItem["category_id"],
      source_id: dataItem["source_id"],
      user_id: dataItem["user_id"],
      cost: dataItem["cost"],
      date: dataItem["date"],
      is_equal: dataItem["is_equal"] ? 1 : 0,
      is_private: dataItem["is_private"] ? 1 : 0,
      notes: dataItem["notes"],
      participants: dataItem["participants"],
      is_new: dataItem.isNew,
      id: dataItem.id,
    };

    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/expanses`,
        body
      );

      global.UF.setAlertVisibility(
        true,
        `Expense ${dataItem.isNew ? "added" : "updated"} successfully`,
        "success"
      );
      if (this.props.updateMenu) {
        this.props.updateMenu();
      }
      if (this.props.handleClose) {
        this.props.handleClose();
      }
    } catch (err) {
      global.UF.handleError(err);
      return;
    }
  };

  render() {
    if (this.state.loading) {
      return <Nx_Loader show={true} />;
    }
    return (
      <Nx_Dialog
        open={true}
        buttons={this.buttons}
        handleClose={this.props.handleClose}
        height={750}
        title={this.state.isNew ? "Add expense" : "Edit expense"}
      >
        {this.renderForm()}
      </Nx_Dialog>
    );
  }
}
