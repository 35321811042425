import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginScreen from "./Login";

class Public extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderElement = () => {
    return (
      <Routes>
        <Route
          path="/"
          element={<LoginScreen onLogin={this.props.onLogin} />}
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    );
  };

  render() {
    return <Fragment>{this.renderElement()}</Fragment>;
  }
}

export default Public;
