import React from "react";
import Nx_Ctrl_Select from "../../../nix_components/ui_components/controls/nx_ctrl_select";
import Nx_Ctrl_DateTime from "../../../nix_components/ui_components/controls/nx_ctrl_datetime";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button";

export default class ProfileFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: null,
      date_to: null,
    };
  }

  handleApplyFilters = () => {
    this.props.handleApplyFilters(this.state);
  };

  render() {
    return (
      <>
        <Grid
          className={this.props.className}
          style={{ gridAutoFlow: "column", width: "50%", gap: "20px" }}
        >
          <Nx_Ctrl_DateTime
            type="number"
            label="From"
            value={this.state.date_from}
            onChange={(date_from) => this.setState({ date_from })}
            fieldConfig={{ min: 0, max: 100, label: "From" }}
          />
          <Nx_Ctrl_DateTime
            type="number"
            label="To"
            value={this.state.date_to}
            fieldConfig={{ min: 0, max: 100, label: "To" }}
            onChange={(date_to) => this.setState({ date_to })}
          />

          <Nx_Button
            label={"Apply"}
            variant="text"
            onClick={this.handleApplyFilters}
          />
        </Grid>
      </>
    );
  }
}
