import React from "react";
import Nx_Ctrl_Select from "../../../nix_components/ui_components/controls/nx_ctrl_select";
import Nx_Ctrl_DateTime from "../../../nix_components/ui_components/controls/nx_ctrl_datetime";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button";

export default class ProfileFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distinct_users: [],
      selected_users: null,
      date_from: null,
      date_to: null,
      chart_type: "Number of activities",
    };
    this.getDistinctUsers();
    this.chartConfig = ["Number of activities", "Total budget"];
  }

  getDistinctUsers = async () => {
    try {
      global.UF.setMaskVisibility(true);
      const response = await global.UF.makeRequest_v2(`GET`, `/api/users`);
      const distinct_users = JSON.parse(response?.text);
      this.setState({ distinct_users }, () => {
        global.UF.setMaskVisibility(false);
      });
    } catch (err) {
      global.UF.handleError(err);
      return;
    }
  };

  handleApplyFilters = () => {
    this.props.handleApplyFilters(this.state);
  };

  render() {
    return (
      <>
        <Nx_Ctrl_Select
          width={200}
          data={this.chartConfig}
          onChange={(chart_type) => this.setState({ chart_type })}
          fieldConfig={{ label: "Choose chart type" }}
          value={this.state.chart_type}
        />

        <Nx_Ctrl_Select
          width={200}
          data={this.state.distinct_users}
          onChange={(selected_users) => this.setState({ selected_users })}
          fieldConfig={{ label: "Choose user", multiple: true }}
          value={this.state.selected_users}
        />

        <Grid
          className={this.props.className}
          style={{ gridAutoFlow: "column", width: "50%", gap: "20px" }}
        >
          <Nx_Ctrl_DateTime
            type="number"
            label="From"
            value={this.state.date_from}
            onChange={(date_from) => this.setState({ date_from })}
            fieldConfig={{ min: 0, max: 100, label: "From" }}
          />
          <Nx_Ctrl_DateTime
            type="number"
            label="To"
            value={this.state.date_to}
            fieldConfig={{ min: 0, max: 100, label: "To" }}
            onChange={(date_to) => this.setState({ date_to })}
          />
          <Nx_Button
            label={"Apply"}
            variant="text"
            onClick={this.handleApplyFilters}
          />
        </Grid>
      </>
    );
  }
}
