import React, { useEffect, useState, useRef } from "react";
import Grid from "../ui_components/layouts/Grid/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import "./nx_grid.scss";
import NxIconButton from "../ui_components/controls/nx_icon_button";
import CloseIcon from "@mui/icons-material/Close";

const NxProgressiveLoader = (props) => {
  const [color, setColor] = useState("#1976d2");
  const [percentage, setPercentage] = useState(0);
  const [message, setMessage] = useState("");
  const [total_seconds, setTotalSeconds] = useState(0);
  const intervalRef = useRef(null);
  const colors = {
    error: "#EC5F5F",
    running: "#2D5DFC",
    completed: "#82D73F",
  };

  const checkStatusOfTable = async () => {
    try {
      const data = await global.UF.dataProvider.get_v2(props.table, {
        id: props.id,
      });

      if (!props.show || !data.length) {
        setPercentage(0);
        setMessage("");
        setTotalSeconds(0);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        return;
      }

      const dataItem = data[0];

      setColor(colors[dataItem.status]);

      let seconds = Math.floor(
        (new Date().getTime() - new Date(dataItem.created_at).getTime()) / 1000
      );

      if (props.table === `inventory`) {
        Math.floor(
          (new Date().getTime() - new Date(dataItem.created_at).getTime()) /
            1000
        );
      }

      setTotalSeconds(seconds);

      if (dataItem.status === "running") {
        if (dataItem.message) {
          const split = dataItem.message.split("|");
          setMessage(split[1]);
          setPercentage(split[0]);
        } else {
          setMessage("Running..");
          setPercentage(0);
        }

        return;
      }

      if (dataItem.status === "error") {
        setMessage(`Error : ${dataItem.message}`);
        clearInterval(intervalRef.current);
        if (props.onError) {
          await props.onError();
        }
        return;
      }

      if (dataItem.status === "completed") {
        clearInterval(intervalRef.current);
        const split = dataItem.message.split("|");
        setMessage(split[1]);
        setPercentage(split[0]);
        if (props.onSuccess) {
          await props.onSuccess();
        }
        props.onClose();
        return;
      }
    } catch (err) {
      console.log(err);
      global.UF.setAlertVisibility(true, err.toString(), "error");
    }
  };

  useEffect(() => {
    if (props.show && !intervalRef.current) {
      intervalRef.current = setInterval(checkStatusOfTable, 3000);
    }

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, []);

  return (
    <Grid
      fullScreen
      style={{
        visibility: props.show ? "visible" : "hidden",
        zIndex: "1500",
        gridAutoFlow: "column",
        position: "relative",
        justifyContent: "unset",
      }}
      className={`${props.className} loader`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          borderRadius: "20px",
          width: "100%",
          height: "100%",
          gap: "50px",
        }}
      >
        {!props.hideCloseButton ? (
          <div style={{ position: "absolute", top: "10px", right: "10px" }}>
            <NxIconButton onClick={() => props.onClose()}>
              <CloseIcon />
            </NxIconButton>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            scale: "1.5",
          }}
        >
          <CircularProgress
            variant="determinate"
            style={{ color: color }}
            value={Number(percentage)}
          />
          <Typography
            variant="caption"
            component="div"
            style={{ position: "absolute", color: color }}
          >
            {`${Math.round(Number(percentage))}%`}
          </Typography>
        </div>

        <Typography
          component="div"
          style={{ color: color, textAlign: "center" }}
        >
          {message}
        </Typography>

        <Typography
          component="div"
          style={{ color: color, textAlign: "center" }}
        >
          {total_seconds} seconds
        </Typography>
      </div>
    </Grid>
  );
};

const NxProgressiveLoaderWrapper = (props) => {
  const [show, setShow] = useState(false || props.show);
  const [table, setTable] = useState("" || props.table);
  const [id, setId] = useState(null || props.id);
  const [onSuccess, setOnSuccess] = useState(null);
  const [onError, setOnError] = useState(null);
  const [className, setClassName] = useState(props.className);

  useEffect(() => {
    if (props.onSuccess) {
      setOnSuccess(() => props.onSuccess);
    }
    if (props.onError) {
      setOnError(() => props.onError);
    }
    if (props.initialization) {
      props.initialization({ buttons: [] });
    }
  }, []);

  const initialize = (bool, tableName, id, success, error, className) => {
    setTable(tableName);
    setId(id);
    setClassName(className);
    if (success) {
      setOnSuccess(() => success);
    }
    if (error) {
      setOnError(() => error);
    }
    setShow(bool);
  };

  global.progressLoadingSetVisibility = initialize;
  if (show) {
    return (
      <NxProgressiveLoader
        table={table}
        id={id}
        show={true}
        onSuccess={onSuccess ? onSuccess : props.onSuccess}
        onError={onError ? onError : props.onError}
        hideCloseButton={props.hideCloseButton}
        className={className}
        onClose={() => {
          setShow(false);
          setTable("");
          setId(0);
        }}
      />
    );
  }

  return null;
};

export default NxProgressiveLoaderWrapper;
