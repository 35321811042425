import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import Nx_Ctrl_Select from "../../../nix_components/ui_components/controls/nx_ctrl_select";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";

export default class ExpansesCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_users: [],
      selected_month: [],
      table: "view_expanses_categories",
    };
  }
  months = [
    { id: 1, label: "January" },
    { id: 2, label: "February" },
    { id: 3, label: "March" },
    { id: 4, label: "April" },
    { id: 5, label: "May" },
    { id: 6, label: "June" },
    { id: 7, label: "July" },
    { id: 8, label: "August" },
    { id: 9, label: "September" },
    { id: 10, label: "October" },
    { id: 11, label: "November" },
    { id: 12, label: "December" },
  ];

  gridProps = {
    fields: ["month_start", "total_cost", "category"],
  };

  fetchData = () => {};

  getFetchData = (fetchData) => {
    this.fetchData = fetchData;
  };

  handleUsersChange = (value) => {
    if (value && value.length) {
      this.gridProps.fields = [
        "month_start",
        "total_cost",
        "category",
        "user_id",
      ];
      this.setState(
        {
          selected_users: value,
          table: "view_expanses_categories_by_user",
        },
        () => {
          global.UF.dataProvider.datastructure[
            "view_expanses_categories_by_user"
          ].filters = {
            user_id: this.state.selected_users,
            custom_filter: `${
              this.state.selected_month.length
                ? `MONTH(month_start) in (${this.state.selected_month.join(
                    ","
                  )})`
                : ""
            }`,
          };
          this.fetchData();
        }
      );
    } else {
      this.gridProps.fields = ["month_start", "total_cost", "category"];
      this.setState({ selected_users: [], table: "view_expanses_categories" });
    }
  };

  handleMonthChange = (value) => {
    this.setState({ selected_month: value }, () => {
      global.UF.dataProvider.datastructure[this.state.table].filters = {
        user_id: this.state.selected_users ? this.state.selected_users : "null",
        custom_filter: `${
          this.state.selected_month.length
            ? `MONTH(month_start) in (${this.state.selected_month.join(",")})`
            : ""
        }`,
      };
      this.fetchData();
    });
  };

  renderCustomFilters = () => {
    return (
      <Grid
        style={{ gridTemplateColumns: "1fr 1fr", gap: "20px" }}
        className="expanses_category_filter"
      >
        <Nx_Ctrl_Select
          width={150}
          label="Users"
          value={this.state.selected_users}
          onChange={this.handleUsersChange}
          fieldConfig={{ multiple: true, reference: "users", label: "Users" }}
        />
        <Nx_Ctrl_Select
          width={150}
          label="Month"
          data={this.months}
          value={this.state.selected_month}
          onChange={this.handleMonthChange}
          fieldConfig={{ multiple: true, label: "Month" }}
        />
      </Grid>
    );
  };

  render() {
    return (
      <Fragment>
        <UF_Content
          key={this.state.table}
          table={this.state.table}
          title="Expanses By Categories"
          gridProps={this.gridProps}
          fetchData={this.getFetchData}
          renderCustomFilters={this.renderCustomFilters()}
          buttons={{}}
        />
      </Fragment>
    );
  }
}
