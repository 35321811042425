import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import DepositsClickModal from "./modals/DepositsClickModal";

export default class Deposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      activeItem: null,
    };
  }

  buttons = {};

  gridProps = {
    fields: ["date", "user_id", "deposit"],
  };

  gridMobileProps = {
    fields: ["date", "user_id", "deposit"],
  };

  formConfig = {
    rows: [["deposit"], [{ field: "user_id" }], ["date"]],
  };

  generateAndSetFilters = (state) => {
    const filters = {};
    const { date_from, date_to } = state;

    const date_from_obj = new Date(date_from);
    const date_to_obj = new Date(date_to);
    const mysqlTimeStampFrom = date_from_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const mysqlTimeStampTo = date_to_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    if (date_from || date_to) {
      filters.custom_filter = `${
        mysqlTimeStampTo ? `'${mysqlTimeStampTo}' >= view_deposits.date` : ""
      } ${mysqlTimeStampTo && mysqlTimeStampFrom ? "AND" : ""} ${
        mysqlTimeStampFrom
          ? `view_deposits.date >= '${mysqlTimeStampFrom}'`
          : ""
      }`;
    }

    this.setState({ filters }, () => {
      global.UF.dataProvider.datastructure["view_deposits"].filters =
        this.state.filters;
      this.fetchData();
    });
  };

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  onSave = async (dataItem, callback) => {
    if (!dataItem.isNew) {
      const response = await global.UF.dataProvider.get_v2(`deposits`, {
        id: dataItem.id,
      });
      const old_record = response[0];
      const old_deposit = old_record.deposit;
      const new_deposit = dataItem.deposit;

      const difference = new_deposit - old_deposit;
      const user_response = await global.UF.dataProvider.get_v2(`users`, {
        id: dataItem.user_id,
      });
      const user = user_response[0];
      user.budget = parseFloat(user.budget, 2) + parseFloat(difference, 2);
      dataItem.Save(() => {
        user.Save(() => {
          global.UF.setAlertVisibility(
            true,
            "Budget successfully updated",
            "success"
          );
          if (callback) {
            if (this.props.updateMenu) {
              this.props.updateMenu();
            }
            callback();
          }
        });
      });
    } else {
      const response = await global.UF.dataProvider.get_v2(`users`, {
        id: dataItem.user_id,
      });
      const user = response[0];
      user.budget =
        parseFloat(user.budget, 2) + parseFloat(dataItem.deposit, 2);
      dataItem.Save(() => {
        user.Save(() => {
          global.UF.setAlertVisibility(
            true,
            "Budget successfully added!",
            "success"
          );
          if (callback) {
            if (this.props.updateMenu) {
              this.props.updateMenu();
            }
            callback();
          }
        });
      });
    }
  };

  handleRowClick = async (row) => {
    this.setState({ activeItem: row.row });
  };

  renderExpandableModal = () => {
    const { id } = this.state.activeItem;
    return (
      <DepositsClickModal
        show={Boolean(this.state.activeItem)}
        handleClose={() => this.setState({ activeItem: null })}
        type_id={id}
        type={"deposit"}
      />
    );
  };

  beforeAddNew = (dataItem, callback) => {
    if (!dataItem.user_id) {
      dataItem.user_id = localStorage.getItem("user_id");
    }
    if (callback) {
      callback(dataItem);
    }
  };

  render() {
    return (
      <Fragment>
        {Boolean(this.state.activeItem) && this.renderExpandableModal()}
        <UF_Content
          table="view_deposits"
          manpulativeTable="deposits"
          title="Deposits"
          permission="user"
          gridProps={this.gridProps}
          gridMobileProps={this.gridMobileProps}
          fetchData={this.getFetchData}
          formConfig={this.formConfig}
          onRowClick={this.handleRowClick}
          beforeAddNew={this.beforeAddNew}
          onSave={this.onSave}
          buttons={this.buttons}
          edit={true}
          export={false}
          delete={false}
          add={true}
          sync={false}
        />
      </Fragment>
    );
  }
}
