import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "../../nix_components/ui_components/components/Menu/Menu.js";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Nx_Loader from "../../nix_components/components/nx_loader";

import { NxBreadcrumbs } from "../../nix_components/ui_components/components/NxBreadcrumbs.jsx";
import withRouter from "../../nix_components/components/withRouter.js";
import SyncIcon from "@mui/icons-material/Sync";

import Users from "./Users/Users";
import Profile from "./Profile/Profile";
import Activities from "./Activities/Activities";
import Deposits from "./Deposits/Deposits";
import Expanses from "./Expanses/Expanses";
import Charts from "./Charts/Charts";
import ProfileInfo from "./Profile/ProfileInfo";

import PersonIcon from "@mui/icons-material/Person";
import FeedIcon from "@mui/icons-material/Feed";
import GroupIcon from "@mui/icons-material/Group";
import SavingsIcon from "@mui/icons-material/Savings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ExpansesCategory from "./ExpansesCategory/ExpansesCategory.jsx";

class Secure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: window.innerWidth < 820 ? false : true,
      loaded: false,
      initializing: true,
      profile: null,
      isMobile: false,
      menuKey: new Date().getTime(),
    };

    this.menuItems = [
      {
        title: "Profile",
        level: 1,
        active: true,
        url: "/profile/:id?",
        icon: <PersonIcon />,
        element: () => {
          return <Profile />;
        },
      },
      {
        title: "Recent activities",
        level: 1,
        url: "/recent-activities/:id?",
        icon: <FeedIcon />,
        element: () => {
          return <Activities />;
        },
      },

      {
        title: "Users",
        level: 1,
        url: "/users/:id?",
        icon: <GroupIcon />,
        element: () => {
          return <Users />;
        },
      },
      {
        title: "Deposits",
        level: 1,
        url: "/deposits/:id?",
        icon: <SavingsIcon />,
        element: () => {
          return <Deposits updateMenu={this.updateMenu} />;
        },
      },
      {
        title: "Expenses",
        level: 1,
        url: "/expenses/:id?",
        icon: <ShoppingCartIcon />,
        element: () => {
          return (
            <Expanses router={this.props.router} updateMenu={this.updateMenu} />
          );
        },
      },
      {
        title: "Expenses By Categories",
        level: 1,
        url: "/expense-by-category",
        icon: <ShowChartIcon />,
        element: () => {
          return <ExpansesCategory router={this.props.router} />;
        },
      },
    ];
  }

  updateMenu = () => {
    this.setState({ menuKey: new Date().getTime() });
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 820 });
  };

  componentDidMount = async () => {
    this.handleProfileInfo();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  onLogOut = () => {
    localStorage.clear("token");
    localStorage.clear("user");
    window.location.href = "/";
  };

  handleMenu = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };

  handleBack = () => {
    if (this.props.router.location.key !== "default") {
      this.props.router.navigate(-1);
    }
  };

  refreshData = () => {
    this.setState({ refresh: false }, () => {
      this.handleProfileInfo();
    });
  };

  handleProfileInfo = async () => {
    try {
      const response = await global.UF.dataProvider.get_v2(
        `view_profile_info`,
        {
          id: localStorage.getItem("user_id"),
        }
      );
      this.setState({ profile: response[0], loaded: true });
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
  };

  renderElement = () => {
    const { profile, isMobile } = this.state;
    this.routes = this.renderRoutes();
    // this.routes.shift(<Route path="/*" element={<Users />} />);
    return (
      <Grid
        fullScreen={true}
        style={{
          backgroundColor: "#F8FAFB",
          gridTemplateColumns: `${this.state.showMenu ? "321px auto" : "auto"}`,
          overflow: "hidden",
          minHeight: "925px",
        }}
      >
        <IconButton
          className="nx_hamburger"
          style={{
            position: "absolute",
            top: "25px",
            left: "25px",
            zIndex: "1000",
          }}
          onClick={this.handleMenu}
        >
          {this.state.showMenu ? (
            <ArrowBack
              style={{
                color: `${
                  this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                }`,
              }}
            />
          ) : (
            <MenuIcon
              style={{
                color: `${
                  this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                }`,
              }}
            />
          )}
        </IconButton>
        <Grid
          className={"nx_menu"}
          style={{
            width: `${this.state.showMenu ? "321px" : "0px"}`,
            transition: `width 1s ease, opacity 1s ease`,
            backgroundColor: "rgb(30, 30, 30)",
            overflow: "hidden",
            overflowY: "auto",
            opacity: `${this.state.showMenu ? "1" : "0"}`,
          }}
          hidden={!this.state.showMenu}
        >
          <Menu
            isMobile={isMobile}
            handleMenu={this.handleMenu}
            items={this.menuItems}
            key={this.state.menuKey}
          />
        </Grid>
        <Grid
          className={"nx_content"}
          style={{ gridTemplateRows: "80px auto" }}
        >
          <Grid>
            <NxBreadcrumbs router={this.props.router} />
            <ProfileInfo
              profile={profile}
              handleProfileInfo={this.handleProfileInfo}
            />
            <div className="nx_refresh">
              <IconButton onClick={() => this.refreshData()}>
                <SyncIcon />
              </IconButton>
            </div>
          </Grid>
          <Routes>{this.routes}</Routes>
        </Grid>
      </Grid>
    );
  };

  renderRoutes = () => {
    if (this.menuItems && this.menuItems.length) {
      return this.menuItems.map((menuItem, index) => {
        if (menuItem.hasOwnProperty("children")) {
          return menuItem.children.map((child, key) => {
            let currentMenuItem = menuItem.children.find(
              (item) => item.url === child.url
            );
            return (
              <Route
                path={`/${currentMenuItem.url.split("/")[2]}`}
                key={key}
                element={currentMenuItem.element()}
              />
            );
          });
        } else {
          let currentMenuItem = this.menuItems.find(
            (item) => item.url === menuItem.url
          );
          const split = currentMenuItem.url.split("/");
          let tempUrl = "";
          if (split.length > 2) {
            split.slice(0, 1);
            tempUrl = split.join("/");
          } else {
            tempUrl = split[1];
          }
          return (
            <Route
              path={`/${tempUrl}`}
              key={index}
              element={currentMenuItem.element()}
            />
          );
        }
      });
    } else return null;
  };

  render() {
    if (!this.state.loaded) {
      return <Nx_Loader show={!this.state.loaded} />;
    } else {
      return <Fragment>{this.renderElement()}</Fragment>;
    }
  }
}

export default withRouter(Secure);
