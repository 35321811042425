import React, { Fragment } from "react";
import NX_Chart from "./NX_Chart";
import ChartsFilter from "./ChartsFilter";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";

export default class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recent_activities: [],
      chart_type: "Number of activities",
    };
  }

  generateAndSetFilters = (state) => {
    const filters = {};
    const { selected_users, chart_type, date_from, date_to } = state;

    if (selected_users && selected_users.length > 0) {
      filters.users = selected_users;
    }

    filters.chart_type = chart_type;

    const date_from_obj = new Date(date_from);
    const date_to_obj = new Date(date_to);
    const mysqlTimeStampFrom = date_from_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const mysqlTimeStampTo = date_to_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    if (date_from || date_to) {
      filters.custom_filter = `${
        mysqlTimeStampTo
          ? `'${mysqlTimeStampTo}' >= view_recent_activities.date`
          : ""
      } ${mysqlTimeStampTo && mysqlTimeStampFrom ? "AND" : ""} ${
        mysqlTimeStampFrom
          ? `view_recent_activities.date >= '${mysqlTimeStampFrom}'`
          : ""
      }`;
    }

    this.setState({ filters, chart_type }, async () => {
      try {
        global.UF.setMaskVisibility(true);
        const response = await global.UF.makeRequest_v2(`POST`, `/api/chart`, {
          filters,
        });
        const recent_activities = response.body;
        this.setState({ recent_activities }, () => {
          global.UF.setMaskVisibility(false);
        });
      } catch (err) {
        global.UF.handleError(err);
        return;
      }
      this.fetchData();
    });
  };

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  componentDidMount = async () => {
    try {
      global.UF.setMaskVisibility(true);
      const response = await global.UF.makeRequest_v2(`POST`, `/api/chart`, {
        filters: {},
      });
      const recent_activities = response.body;
      this.setState({ recent_activities }, () => {
        global.UF.setMaskVisibility(false);
      });
    } catch (err) {
      global.UF.handleError(err);
      return;
    }
  };

  render() {
    return (
      <Fragment>
        <Grid
          className={"uf_content_wrapper"}
          style={{
            gridTemplateRows: `${"min-content auto"}`,
          }}
        >
          <Grid className={"uf_content_upper"}>
            <Grid className={"uf_content_title"}>Chart</Grid>
          </Grid>
          <Grid className="uf_content_main">
            <Grid
              className={"uf_content_main_container"}
              style={{
                width: "100%",
                height: "auto",
                gridAutoFlow: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <div className="uf_filters nx_charts_filter ">
                <ChartsFilter
                  {...this.props}
                  className={"nx_charts_filter "}
                  handleApplyFilters={(filters) =>
                    this.generateAndSetFilters(filters)
                  }
                />
              </div> */}
            </Grid>
            <div style={{ flexDirection: "column", width: "100%" }}>
              {this.state.recent_activities && (
                <NX_Chart
                  chart_type={this.state.chart_type}
                  data={this.state.recent_activities}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
