import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Nx_Button from "../controls/nx_button";

const NxConfirm = (props) => {
  return (
    <Dialog open={props.show} onClose={props.onClose}>
      <DialogTitle style={{ textAlign: "left", fontWeight: "bold" }}>
        {"Confirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Nx_Button onClick={props.onClose} label={"Cancel"} variant={"text"} />
        <Nx_Button onClick={props.onClick} label={"Yes"} variant={"text"} />
      </DialogActions>
    </Dialog>
  );
};

export default NxConfirm;
