import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import ExpansesClickModal from "./modals/ExpansesClickModal";
import ExpansesForm from "./modals/ExpansesForm";

export default class Expanses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: null,
      profile: null,
      user_id: localStorage.getItem("user_id"),
      date: null,
    };
  }

  gridProps = {
    fields: ["date", "category_id", "user_id", "cost", "source_id"],
  };

  gridMobileProps = {
    fields: ["date", "category_id", "user_id", "cost"],
  };

  buttons = {};

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  renderCustomFormDialog = (dataItem, handleClose) => {
    return (
      <ExpansesForm
        dataItem={dataItem}
        user_id={this.state.user_id}
        handleClose={handleClose}
        updateMenu={this.props.updateMenu}
      />
    );
  };

  onDataItemsLoad = async (data, callback) => {
    if (!data || !data.length) {
      if (callback) {
        callback();
      }
      return;
    }

    for (const item of data) {
      let participants = await global.UF.dataProvider.get_v2(
        `view_participations`,
        { expanse_id: item.id }
      );
      participants = participants.map((participant) => {
        return {
          id: participant.user_id,
          label: participant.user,
          cost: participant.cost,
        };
      });
      item.participants = participants;
    }

    if (callback) {
      callback(data);
    }
  };

  beforeAddNew = (dataItem, callback) => {
    dataItem.participants = [];
    if (callback) {
      callback();
    }
  };

  render() {
    return (
      <Fragment>
        {Boolean(this.state.activeItem) && this.renderExpandableModal()}
        <UF_Content
          table="view_expanses"
          manpulativeTable="expanses"
          title="Expanses"
          permission="user"
          gridProps={this.gridProps}
          gridMobileProps={this.gridMobileProps}
          fetchData={this.getFetchData}
          formConfigAdd={this.formConfigAdd}
          formConfig={this.formConfig}
          renderCustomFormDialog={this.renderCustomFormDialog}
          onDataItemsLoad={this.onDataItemsLoad}
          beforeAddNew={this.beforeAddNew}
          buttons={this.buttons}
          edit={true}
          export={false}
          delete={false}
          add={true}
          sync={false}
        />
      </Fragment>
    );
  }
}
