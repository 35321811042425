import React from "react";
import PropTypes from "prop-types";
import Nx_Ctrl_TextInput from "../../nix_components/ui_components/controls/nx_ctrl_textinput";
import "./set_password_modal.scss";
import Nx_Dialog from "../../nix_components/ui_components/dialogs/nx_dialog";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";
import { validators } from "../../nix_components/data/data_validators.js";

class SetPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      confirmPassword: null,
      isError: false,
      errMsg: "",
    };
  }

  buttons = [
    {
      label: "Cancel",
      variant: "outlined",
      onClick: this.props.onClose,
    },
    {
      label: "Save",
      onClick: () => this.setPassword(),
    },
  ];

  setPassword = () => {
    const isError = this.handleValidation();
    if (isError) {
      this.setState({ isError });
      return;
    }

    this.props.onSetPassword(this.state.password);
    this.setState({ isError: false, errMsg: "" }, () => {
      this.props.onClose();
    });
  };

  validatePassword = (password) => {
    const validatorsArr = [
      validators.isNull,
      validators.isEmpty,
      validators.isNotValidPassword,
    ];
    let notValid = false;
    let error = "";
    validatorsArr.forEach((validator) => {
      if (!notValid) {
        notValid = validator.func(password);
        if (notValid) {
          error = validator.msg;
        }
      }
    });
    this.setState({ errMsg: error });
    return notValid;
  };

  handleValidation = () => {
    if (
      !this.validatePassword(this.state.password) &&
      !this.validatePassword(this.state.confirmPassword)
    ) {
      const matches = this.state.password === this.state.confirmPassword;
      if (!matches) {
        this.setState({ errMsg: "Passwords doesn't match" });
        return true;
      }
      return false;
    }
    return true;
  };

  onChange = (val, field) => {
    this.state[field] = val;
    this.forceUpdate();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.setPassword();
    }
  };

  render() {
    return (
      <Nx_Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        width="350px"
        title={`Set user password`}
        buttons={this.buttons}
      >
        <Grid className={"set_password_wrapper"}>
          <Nx_Ctrl_TextInput
            onChange={(val) => this.onChange(val, `password`)}
            type={"password"}
            label={"Password"}
            error={this.state.isError}
            helperText={this.state.errMsg}
            value={this.state.password}
            onKeyDown={this.onKeyDown}
          />
          <Nx_Ctrl_TextInput
            onChange={(val) => this.onChange(val, `confirmPassword`)}
            label={"Confirm password"}
            type={"password"}
            error={this.state.isError}
            helperText={this.state.errMsg}
            value={this.state.confirmPassword}
            onKeyDown={this.onKeyDown}
          />
        </Grid>
      </Nx_Dialog>
    );
  }
}

SetPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default SetPasswordModal;
