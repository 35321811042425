import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import PasswordIcon from "@mui/icons-material/Password";
import SetPasswordModal from "../../../components/modals/SetPasswordModal";
import NxIconButton from "../../../nix_components/ui_components/controls/nx_icon_button";
import UsersFilter from "./UsersFilters";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordItem: null,
      loaded: false,
      filters: null,
    };
  }

  buttons = {
    setPassword: (params) => {
      return (
        <NxIconButton
          tooltip="Set Password"
          aria-label="edit"
          color="default"
          onClick={() => this.setState({ passwordItem: params.row })}
        >
          <PasswordIcon />
        </NxIconButton>
      );
    },
  };

  formConfigAdd = {
    rows: [["is_active"], ["first_name", "last_name"], ["email", {}]],
  };

  formConfigEdit = {
    rows: [["is_active"], ["first_name", "last_name"], ["email", {}]],
  };

  gridProps = {
    fields: [
      "first_name",
      "last_name",
      "email",
      // "number",
      "budget",
      "is_active",
    ],
  };

  gridMobileProps = {
    fields: ["first_name", "last_name", "budget"],
  };

  setPasswordItemNull = () => {
    this.state.passwordItem = null;
    this.forceUpdate();
  };

  setPassword = (password, user) => {
    global.UF.makeRequest(
      `POST`,
      `/api/set-password`,
      { id: user.id, password: password },
      true,
      () => {
        this.setState({ passwordItem: null });
      },
      (error) => {
        if (error) {
          console.error(error);
        }
      }
    );
  };

  renderSetPasswordModal = () => {
    return (
      <SetPasswordModal
        open={this.state.passwordItem !== null}
        onClose={() => this.setState({ passwordItem: null })}
        onSetPassword={(password) =>
          this.setPassword(password, this.state.passwordItem)
        }
      />
    );
  };

  fetchData = () => {};

  getFetchData = (fetchData, filters) => {
    this.fetchData = fetchData;
    this.setState({ filters });
  };

  onSave = (dataItem, callback) => {
    dataItem.email = dataItem.email.trim().toLowerCase();
    dataItem.Save(async (response) => {
      if (response?.hasOwnProperty("error")) {
        global.UF.setAlertVisibility(true, response.error, "error");
        return;
      }

      if (dataItem.isNew && response.hasOwnProperty("id") && response.id) {
        const id = response.id;
        try {
          await global.UF.makeRequest_v2(
            `POST`,
            `/api/set-password`,
            { id: id, password: dataItem.password },
            true
          );

          if (callback) {
            callback();
          }
        } catch (err) {
          console.error(err);
          global.UF.setAlertVisibility(true, err.toString(), "error");
        }
      } else {
        if (callback) {
          callback();
        }
      }
    });
  };

  onAdd = () => {
    const dataItem = global.UF.dataProvider.datastructure["users"].new();
    dataItem.customer_id = this.props.id;
    dataItem.budget = 0;
    return dataItem;
  };

  generateAndSetFilters = (state) => {
    const filters = {};
    const { date_from, date_to } = state;

    const date_from_obj = new Date(date_from);
    const date_to_obj = new Date(date_to);
    const mysqlTimeStampFrom = date_from_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const mysqlTimeStampTo = date_to_obj
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    if (date_from || date_to) {
      filters.custom_filter = `${
        mysqlTimeStampTo ? `'${mysqlTimeStampTo}' >= users.created_at` : ""
      } ${mysqlTimeStampTo && mysqlTimeStampFrom ? "AND" : ""} ${
        mysqlTimeStampFrom ? `users.created_at >= '${mysqlTimeStampFrom}'` : ""
      }`;
    }

    this.setState({ filters }, () => {
      global.UF.dataProvider.datastructure["users"].filters =
        this.state.filters;

      this.fetchData();
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <UF_Content
          table="users"
          title="Users"
          permission="user"
          gridProps={this.gridProps}
          gridMobileProps={this.gridMobileProps}
          onSave={this.onSave}
          fetchData={this.getFetchData}
          onAdd={this.onAdd}
          buttons={this.buttons}
          edit={true}
          export={false}
          delete={true}
          formConfigAdd={this.formConfigAdd}
          formConfig={this.formConfigEdit}
          add={true}
          sync={false}
        />
      </Fragment>
    );
  }
}
